import { SDK } from "../shareact/sdk"

const client =
    typeof window !== "undefined"
        ? new SDK({
              url: process.env.API_URL,
              version: process.env.API_VERSION,
              storage: window.localStorage,
              storageKey: process.env.STORAGE_KEY,
              crossDomain: process.env.COOKIE_DOMAIN,
              uid: process.env.API_UID,
              password: process.env.API_PASSWORD,
          })
        : {}

// for dev purpose make client a global variable
if (process.env.NODE_ENV === "development" && typeof window !== "undefined") {
    window.client = client
}

export default client
