import React, { useLayoutEffect, useRef, useState } from "react"

import ContentWrapper from "../universal/content-wrapper"
import SliderStyles from "./slider.module.css"
import Spacer from "../universal/spacer"
import classNames from "classnames"
import { useDimensions } from "../../shareact/hooks/use-dimensions"
import { useSlider } from "../../hooks/use-slider"

const Slider = props => {
    const { children } = props

    const [offset, setOffset] = useState(0)
    const sliderRef = useRef(null)
    const offsetRef = useRef(null)

    const { handleDragStart, handleDragMove, handleDragEnd, index, lastIndex, transition, goTo } = useSlider(
        children.length,
        sliderRef,
        0,
        undefined,
        props.active,
        props.setActive
    )

    useLayoutEffect(() => {
        const calcOffset = () => {
            if (!offsetRef.current) return
            return setOffset(offsetRef.current.getBoundingClientRect().left)
        }

        calcOffset()
        window.addEventListener("resize", calcOffset)

        return () => window.removeEventListener("resize", calcOffset)
    }, [])

    const { width } = useDimensions()

    return (
        <div
            className={classNames(SliderStyles.wrapper, {
                [SliderStyles.hideNavigation]: props.hideNavigation,
            })}
            ref={sliderRef}
        >
            <div
                className={classNames(SliderStyles.slider, {
                    [SliderStyles.transition]: transition,
                    [SliderStyles.draggable]: children.length > 1,
                })}
                style={{
                    width: `calc(((100% - 2 * ${offset}px) / ${props.columns}) * ${children.length} + (${
                        (width < 767 ? 15 : width < 1365 ? 20 : 24) / props.columns
                    }px * ${children.length - props.columns}))`,
                    minWidth: 230 * children.length + 15 * (children.length - 1),
                    transform: `translateX(${-1 * index * (100 / children.length)}%)`,
                    gridTemplateColumns: `repeat(${children.length}, 1fr)`,
                    marginLeft: offset,
                }}
                onTouchStart={e => handleDragStart(e, true)}
                onMouseDown={e => handleDragStart(e, false)}
                onTouchMove={e => handleDragMove(e, true)}
                onMouseMove={e => handleDragMove(e, false)}
                onTouchEnd={() => handleDragEnd(true)}
                onMouseUp={() => handleDragEnd(false)}
                onMouseLeave={() => handleDragEnd(false)}
                role="none"
            >
                {children}
            </div>
            <ContentWrapper>
                {children.length > 1 && (
                    <div className={SliderStyles.navigation} ref={offsetRef}>
                        {!props.hideNavigation && (
                            <>
                                <Spacer medium />
                                <div>
                                    <p className={SliderStyles.pagination}>
                                        {lastIndex + 1}/{children.length}
                                    </p>
                                    <button
                                        className={classNames(SliderStyles.arrow, SliderStyles.arrowLeft, {
                                            [SliderStyles.disabled]: lastIndex < 1,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex - 1)}
                                    >
                                        <i className="lavita-icon"></i>
                                    </button>
                                    <button
                                        className={classNames(SliderStyles.arrow, {
                                            [SliderStyles.disabled]: lastIndex + 1 >= children.length,
                                        })}
                                        type="button"
                                        onClick={() => goTo(lastIndex + 1)}
                                    >
                                        <i className="lavita-icon" style={{ transform: "scaleX(-1)" }}>
                                            
                                        </i>
                                    </button>
                                    {children.map((_, i) => (
                                        // eslint-disable-next-line
                                        <button
                                            type="button"
                                            className={classNames(SliderStyles.dot, {
                                                [SliderStyles.whiteNavigation]: props.whiteNavigation,
                                                [SliderStyles.activeDot]: lastIndex === i,
                                            })}
                                            onClick={() => goTo(i)}
                                            key={i}
                                        ></button>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </ContentWrapper>
        </div>
    )
}

Slider.defaultProps = {
    columns: 2,
}

export default Slider
