import BackgroundStyles from "./background.module.css"
import { LazyImage } from "../../shareact/components/universal/image"
import React from "react"
import classNames from "classnames"

const Background = props => {
    return (
        <div
            className={classNames(BackgroundStyles.background, {
                [BackgroundStyles.minHeight]: props.minHeight,
                [BackgroundStyles.noChildren]: !props.children,
            })}
            style={{ background: props.background }}
        >
            {props.imageMobile && (
                <div className={BackgroundStyles.imageMobile}>
                    <LazyImage
                        url="assets"
                        path={props.imageMobile}
                        srcSet={[{ w: 717, default: true }, { w: 1434 }]}
                        sizes="100%"
                    />
                </div>
            )}
            {props.children}
            {props.image && (
                <div
                    className={classNames(BackgroundStyles.image, {
                        [BackgroundStyles.alwaysImage]: !props.imageMobile,
                    })}
                >
                    <LazyImage
                        url="assets"
                        path={props.image}
                        srcSet={[{ w: 1920, default: true }, { w: 3840 }]}
                        sizes="100%"
                    />
                    {props.shadow && <div className={BackgroundStyles.shadow} />}
                </div>
            )}
        </div>
    )
}

export default Background
