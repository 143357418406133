import "./base.css"

import React from "react"
import TextareaStyles from "./textarea.module.css"
import WrapWithError from "./wrap-with-error"
import classNames from "classnames"
import { useController } from "react-hook-form"
import { useTranslation } from "react-i18next"

const Textarea = ({
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
    length,
    showLengthInfo,
    type,
    transform,
    placeholder,
    label,
}) => {
    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error, isTouched },
    } = useController({
        name,
        control,
        rules,
        defaultValue,
        shouldUnregister,
    })

    const { t } = useTranslation("shared")

    const info = length &&
        showLengthInfo && {
            message: t("remaining_chars", { count: length - value.length }),
        }

    return (
        <div
            className={classNames(TextareaStyles.block, {
                error: error,
                wasInFocus: (isTouched && value) || value || placeholder,
            })}
        >
            <WrapWithError error={error} info={info}>
                <textarea
                    type={type}
                    name={name}
                    id={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={e => onChange(transform ? transform.output(e.target.value) : e)}
                    className="input"
                    ref={ref}
                    maxLength={length}
                    placeholder={placeholder}
                />
                <label htmlFor={name} className={"label"}>
                    {label}
                </label>
            </WrapWithError>
        </div>
    )
}

export default Textarea
