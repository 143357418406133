import React, { useState } from "react"

import Button from "./universal/button"
import FlexWrapper from "../shareact/components/fields/flex-wrapper"
import Input from "../shareact/components/fields/input"
import LinkButtonWrapper from "./universal/link-button-wrapper"
import Spacer from "./universal/spacer"
import Textarea from "../shareact/components/fields/textarea"
import client from "../utils/client"
import t from "../locales/de/translations.json"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"

const Form = () => {
    const [loading, setLoading] = useState(false)
    const { handleSubmit, control, reset } = useForm({
        mode: "onTouched",
        defaultValues: {
            firstname: "",
            lastname: "",
            email: "",
            textarea: "",
        },
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
    })

    const submit = async (values) => {
        try {
            setLoading(true)
            client.postContact({
                category_id: 7,
                firstname: values.firstname,
                lastname: values.lastname,
                email: values.email,
                body: values.textarea,
            })
            toast.success(t("form_success"), { autoClose: 5000 })
            reset()
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)} name="form">
            <FlexWrapper flex={["2 1 auto", "2 1 auto"]}>
                <Input
                    control={control}
                    rules={{ required: { value: true, message: t["form_error_required"] } }}
                    type="text"
                    name="firstname"
                    label={t["form_firstname_label"]}
                    autocomplete="given-name"
                />
                <Input
                    control={control}
                    rules={{ required: { value: true, message: t["form_error_required"] } }}
                    type="text"
                    name="lastname"
                    label={t["form_lastname_label"]}
                    autocomplete="family-name"
                />
            </FlexWrapper>
            <Input
                control={control}
                rules={{ required: { value: true, message: t["form_error_required"] } }}
                type="email"
                name="email"
                label={t["form_email_label"]}
                autocomplete="email"
            />
            <Textarea
                control={control}
                rules={{ required: { value: true, message: t["form_error_required"] } }}
                name="textarea"
                label={t["form_textarea_label"]}
            />
            <small>{t["form_smallprint"]}</small>
            <Spacer small />
            <LinkButtonWrapper>
                <Button submit title={t["form_submit"]} disabled={loading} />
            </LinkButtonWrapper>
        </form>
    )
}

export default Form
