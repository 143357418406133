import { useEffect, useState } from "react"

const useIntersectionObserver = ref => {
    const [intersected, setIntersected] = useState(false)

    useEffect(() => {
        if (!ref?.current) return

        const observer = new IntersectionObserver(
            entries => {
                const entry = entries[0]
                if (entry.isIntersecting) {
                    setIntersected(true)
                    observer.disconnect()
                }
            },
            {
                rootMargin: "256px 256px 256px 256px",
            }
        )

        observer.observe(ref.current)
        return () => {
            observer.disconnect()
        }
    }, [ref])

    return intersected
}

export { useIntersectionObserver }
