import React from "react"
import VideoButtonStyles from "./video-button.module.css"
import classNames from "classnames"

const PlayButton = props => {
    return (
        <div className={VideoButtonStyles.button}>
            <svg className={VideoButtonStyles.progress} width="50px" height="50px" viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="24" strokeWidth="2" />
                <circle
                    cx="25"
                    cy="25"
                    r="24"
                    strokeWidth="2"
                    style={{
                        strokeDashoffset: 2 * Math.PI * 25 * (1 - props.progress / 100),
                        strokeDasharray: 2 * Math.PI * 25,
                    }}
                />
            </svg>
            <i className={classNames("lavita-icon", { [VideoButtonStyles.paused]: !props.plays })}>{props.plays ? "" : ""}</i>
        </div>
    )
}

const MuteButton = props => {
    return (
        <div className={VideoButtonStyles.button}>
            <i className={"lavita-icon"}>{props.muted ? "" : ""}</i>
        </div>
    )
}

export { PlayButton, MuteButton }
