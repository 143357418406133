import { Authentication } from "./auth"
import { querify } from "./utils/params"

export class APIError extends Error {
    constructor(message, info) {
        super(message) // 'Error' breaks prototype chain here
        this.message = message
        this.info = info
        Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    }

    get endpoint() {
        return this.info.endpoint
    }

    get method() {
        return this.info.method.toUpperCase()
    }

    get params() {
        return this.info.params || {}
    }

    get status() {
        return this.info.status
    }

    get code() {
        return this.info.code
    }

    get type() {
        return this.message
    }

    get msg() {
        return this.info.msg
    }

    get id() {
        return this.info.id
    }

    get detail() {
        return this.info.detail
    }

    toString() {
        return [
            "Legacy API call failed:",
            `${this.method} ${this.endpoint} ${JSON.stringify(this.params)} - `,
            this.status,
            this.msg || this.message,
            `(code ${this.code})`,
        ].join(" ")
    }
}

export class API {
    constructor(options, config, reAuth) {
        this.options = options
        this.config = config
        this.reAuth = reAuth
        this.auth = new Authentication(options, config, {
            post: this.post.bind(this),
        })
        this.request = this.request.bind(this)
    }

    convertIdsToString(values) {
        if (typeof values !== "object" || values === null) return

        Object.keys(values).map(key => {
            if (key.endsWith("id")) {
                values[key] += ""
                return undefined
            }

            return this.convertIdsToString(values[key])
        })
    }

    async get(endpoint, authType, language, params) {
        return await this.request("GET", endpoint, authType, language, params)
    }

    async post(endpoint, authType, language, body, params) {
        return await this.request("POST", endpoint, authType, language, params, body)
    }

    async patch(endpoint, authType, language, body, params) {
        return await this.request("PATCH", endpoint, authType, language, params, body)
    }

    async put(endpoint, authType, language, body, params) {
        return await this.request("PUT", endpoint, authType, language, params, body)
    }

    async delete(endpoint, authType, language, params) {
        return await this.request("DELETE", endpoint, authType, language, params)
    }

    async request(method, endpoint, authType, language = "de", params, data = null, headers = {}, retry = 1) {
        if (!this.options.url) {
            throw new Error("SDK has no URL configured to send requests to.")
        }

        let baseURL = `${this.options.url}${this.options.version}`

        const query =
            params &&
            Object.keys(params).length &&
            Object.values(params).filter(p => p || typeof p === "boolean").length
                ? "?" + querify(params)
                : ""

        if (authType) {
            headers = {
                Authorization: "Bearer " + this.config[authType],
            }
        }

        try {
            const response = await fetch(baseURL + endpoint + query, {
                method: method,
                headers: {
                    accept: "application/json",
                    "content-type": "application/json",
                    "accept-language": language,
                    ...headers,
                },
                mode: "cors",
                credentials: "include",
                body: data ? JSON.stringify(data) : undefined,
            })

            let responseData
            const contentType = response.headers.get("content-type")
            if (response.status !== 204 && contentType && contentType.indexOf("application/json") !== -1) {
                responseData = await response.json()
            } else if (response.status !== 204 && response.ok) {
                responseData = await response.blob()
                return URL.createObjectURL(responseData)
            }

            if (!responseData) responseData = {}

            if (response.ok || response.status === 402) {
                this.convertIdsToString(responseData)
                return responseData.data
            }
            if (response.status === 401 && endpoint !== "/auth/customer") {
                if (responseData.error.type === "AccessDenied") {
                    return this.auth.logout()
                } else {
                    if (retry <= 0) return this.auth.logout()

                    if (authType === "consumerToken") {
                        await this.auth.authConsumer()
                        return await this.request(
                            method,
                            endpoint,
                            authType,
                            language,
                            params,
                            data,
                            headers,
                            retry - 1
                        )
                    } else if (authType === "customerWeakToken" || authType === "customerStrongToken") {
                        if (!this.config[authType]) return this.auth.logout()

                        await this.reAuth()
                        return await this.request(
                            method,
                            endpoint,
                            authType,
                            language,
                            params,
                            data,
                            headers,
                            retry - 1
                        )
                    }
                }
            }

            const error = {
                response: response,
                data: responseData.error,
            }
            throw error
        } catch (error) {
            const errorResponseData = error.data
            this.convertIdsToString(errorResponseData)
            const baseErrorInfo = {
                error,
                endpoint: endpoint,
                method: method,
                params: params,
                status: error.response?.status,
                code: errorResponseData?.code,
                msg: errorResponseData?.message,
                id: errorResponseData?.id,
                detail: errorResponseData?.detail,
            }

            if (error.response) {
                throw new APIError(errorResponseData?.type || "Unknown error occured", baseErrorInfo)
            } else {
                throw new APIError("Network error", baseErrorInfo)
            }
        }
    }
}
