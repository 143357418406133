import ButtonStyles from "./button.module.css"
import React from "react"
import classNames from "classnames"

const Button = (props) => {
    const { id, action, submit, icon, title, disabled, ...rest } = props

    const classes = classNames(ButtonStyles.button, {
        [ButtonStyles.disabled]: disabled,
    })

    return disabled ? (
        <button disabled id={id} className={classes} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : submit ? (
        <button type="submit" id={id} className={classes} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    ) : (
        <button type="button" id={id} className={classes} onClick={action} {...rest}>
            {icon && <i className="lavita-icon">{icon}</i>}
            <span>{title}</span>
        </button>
    )
}

export default Button
