import { openOverlay, useOverlayDispatch } from "../shareact/context/overlay"

import HeroDockStyles from "./hero-dock.module.css"
import { LazyImage } from "../shareact/components/universal/image"
import { PlayButton } from "./universal/video-button"
import React from "react"
import Video from "./universal/self-hosted-video"
import t from "../locales/de/translations.json"

const HeroDock = (props) => {
    const overlayDispatch = useOverlayDispatch()

    return (
        <div className={HeroDockStyles.shadow}>
            <div className={HeroDockStyles.block}>
                {props.dock.map((d, i) => (
                    <React.Fragment key={d.id || i}>
                        <div className={HeroDockStyles.items}>
                            <div className={HeroDockStyles.icon}>
                                <i className="lavita-icon">{d.icon}</i>
                            </div>
                            <div>
                                <p>{d.text}</p>
                            </div>
                        </div>
                        {i !== props.dock.length - 1 && <div className={HeroDockStyles.border} />}
                    </React.Fragment>
                ))}
                {props.video && (
                    <button
                        type="button"
                        onClick={() =>
                            openOverlay(
                                overlayDispatch,
                                <Video videos={props.video.videos} autoPlay muted={false} />,
                                null,
                                true
                            )
                        }
                        className={HeroDockStyles.video}
                    >
                        <PlayButton />
                        <p>{t["icon_action_video"]}</p>
                        <LazyImage
                            url="assets"
                            path={props.video.image}
                            srcSet={[{ h: 125, default: true }, { h: 250 }]}
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default HeroDock
