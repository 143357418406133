import QuoteSliderItemStyles from "./quote-slider-item.module.css"
import React from "react"
import Text from "../universal/text"

const QuoteSliderItem = (props) => {
    return (
        <div className={QuoteSliderItemStyles.block}>
            <span className={QuoteSliderItemStyles.quote}>
                <i className="lavita-icon" style={{ color: props.color }}>
                    
                </i>
            </span>
            <Text source={props.source} />
            {props.smallprint && <small dangerouslySetInnerHTML={{ __html: props.smallprint }} />}
        </div>
    )
}

export default QuoteSliderItem
