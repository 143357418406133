import Background from "../components/universal/background"
import BigIcon from "../components/big-icon"
import ContentWrapper from "../components/universal/content-wrapper"
import Footer from "../components/layout/footer"
import Form from "../components/form"
import Header from "../components/layout/header"
import HeroDock from "../components/hero-dock"
import IconWithCircle from "../components/universal/icon-with-circle"
import Overlay from "../shareact/components/overlay"
import QuoteSliderItem from "../components/slider/quote-slider-item"
import React from "react"
import Relative from "../components/universal/relative"
import Seo from "../components/universal/seo"
import Sitemap from "../components/sitemap"
import Slider from "../components/slider/slider"
import Spacer from "../components/universal/spacer"
import Text from "../components/universal/text"
import { findTranslation } from "../shareact/utils/find-translation"
import { graphql } from "gatsby"

const language = "de"

const Index = (props) => {
    const components = props.data.directusLandingPage.page_component

    return (
        <>
            <Header />
            <main>
                <Spacer />
                <ContentWrapper>
                    <Text source={findTranslation(components[0].text[0], language).markdown} />
                    <Spacer size={{ desktop: "none", tablet: "none", mobile: "default" }} />
                    <HeroDock
                        dock={components[2].block_with_icon.map((item) => ({
                            icon: item.icon,
                            text: findTranslation(item, language).text,
                        }))}
                        video={{
                            image: components[3].image[0].image,
                            videos: components[3].self_hosted_video,
                        }}
                    />
                    <Spacer size={{ desktop: "none", tablet: "none", mobile: "default" }} />
                </ContentWrapper>
                <Background
                    image={components[1].hero_image[0].image}
                    imageMobile={components[1].hero_image[0].image_mobile}
                    minHeight
                />
                {components
                    .filter((c) => c.type === "erfahrungen")
                    .slice(0, 8)
                    .map((c) => (
                        <React.Fragment key={c.id}>
                            <Background background={c.erfahrungen[0].color}>
                                <ContentWrapper>
                                    <Spacer />
                                    <IconWithCircle
                                        color={c.erfahrungen[0].color}
                                        icon={c.erfahrungen[0].icon}
                                        background="#fff"
                                    />
                                    <Spacer small />
                                    <Text
                                        color={{ desktop: "white", tablet: "white", mobile: "white" }}
                                        maxWidth={{ desktop: "50%", tablet: "50%", mobile: "100%" }}
                                        source={findTranslation(c.erfahrungen[0], language)?.text}
                                    />
                                    <Spacer large />
                                    <Spacer size={{ desktop: "none", tablet: "none", mobile: "default" }} />
                                </ContentWrapper>
                            </Background>
                            <Relative>
                                <Slider columns={2}>
                                    {c.erfahrungen[0].erfahrung.map((e) => (
                                        <QuoteSliderItem
                                            key={e.id}
                                            color={c.erfahrungen[0].color}
                                            source={findTranslation(e, language).text}
                                            smallprint={findTranslation(e, language).smallprint}
                                            noMargin
                                            foldOut
                                        />
                                    ))}
                                </Slider>
                            </Relative>
                        </React.Fragment>
                    ))}
                <Background
                    image={components[12].image[0].image}
                    imageMobile={components[12].image[0].image_mobile}
                    minHeight
                />
                <Spacer />
                <ContentWrapper>
                    <Text bigger source={findTranslation(components[13].text[0], language).markdown} />
                </ContentWrapper>
                <Spacer />
                {components
                    .filter((c) => c.type === "erfahrungen")
                    .slice(8, 12)
                    .map((c) => (
                        <React.Fragment key={c.id}>
                            <Background background={c.erfahrungen[0].color}>
                                <ContentWrapper>
                                    <Spacer />
                                    <IconWithCircle
                                        color={c.erfahrungen[0].color}
                                        icon={c.erfahrungen[0].icon}
                                        background="#fff"
                                    />
                                    <Spacer small />
                                    <Text
                                        color={{ desktop: "white", tablet: "white", mobile: "white" }}
                                        maxWidth={{ desktop: "50%", tablet: "50%", mobile: "100%" }}
                                        source={findTranslation(c.erfahrungen[0], language)?.text}
                                    />
                                    <Spacer large />
                                    <Spacer size={{ desktop: "none", tablet: "none", mobile: "default" }} />
                                </ContentWrapper>
                            </Background>
                            <Relative>
                                <Slider relativePosition withNav childWidths={[708, 230]}>
                                    {c.erfahrungen[0].erfahrung.map((e) => (
                                        <QuoteSliderItem
                                            key={e.id}
                                            color={c.erfahrungen[0].color}
                                            source={findTranslation(e, language).text}
                                            smallprint={findTranslation(e, language).smallprint}
                                            noMargin
                                            foldOut
                                        />
                                    ))}
                                </Slider>
                            </Relative>
                        </React.Fragment>
                    ))}
                <Background
                    image={components[18].image[0].image}
                    imageMobile={components[18].image[0].image_mobile}
                    shadow
                >
                    <ContentWrapper>
                        <Spacer />
                        <BigIcon icon="" />
                        <Spacer small />
                        <Text
                            source={findTranslation(components[18].text[0], language).markdown}
                            color={{ desktop: "white", tablet: "white", mobile: "black" }}
                            maxWidth={{ desktop: "50%", tablet: "50%", mobile: "100%" }}
                        />
                        <Spacer />
                    </ContentWrapper>
                </Background>
                <Spacer />
                <ContentWrapper>
                    <Form />
                </ContentWrapper>
                <Spacer />
            </main>
            <Overlay />
            <Footer />
            <Sitemap hidden />
        </>
    )
}

export default Index

export const Head = ({ location, data }) => {
    const node = data.directusLandingPage

    return (
        <Seo
            title={findTranslation(node, language, "title")}
            description={findTranslation(node, language, "description")}
            pathname={location.pathname}
        />
    )
}

export const query = graphql`
    query {
        directusLandingPage(slug: { eq: "erfahrungen" }) {
            translations {
                language
                title
                description
                breadcrumb
            }
            robots
            page_component {
                id
                type
                hero_image {
                    image
                    image_mobile
                }
                text {
                    translations {
                        language
                        markdown
                    }
                    id
                }
                block_with_icon {
                    color
                    icon
                    translations {
                        language
                        text
                    }
                    id
                }
                self_hosted_video {
                    src
                    type
                    id
                }
                image {
                    image
                    image_mobile
                }
                icon {
                    icon
                }
                erfahrungen {
                    id
                    translations {
                        text
                        language
                    }
                    icon
                    color
                    erfahrung {
                        id
                        translations {
                            text
                            smallprint
                            language
                        }
                    }
                }
            }
        }
    }
`
