import { useCallback, useEffect, useRef, useState } from "react"

const useSlider = (length, sliderRef, startIndex = 0, resetDependencies = [], active, setActive) => {
    const [index, setIndex] = useState(startIndex)
    const [lastIndex, setLastIndex] = useState(startIndex)
    const [transition, setTransition] = useState(true)
    const dragStart = useRef(0)
    const dragStartTime = useRef(new Date())
    const mouseDown = useRef(false)
    const slideWidth = useRef(0)
    const clientXStart = useRef()
    const clientX = useRef()

    useEffect(() => {
        setIndex(startIndex)
        setLastIndex(startIndex)
        // eslint-disable-next-line
    }, [startIndex, ...resetDependencies])

    const goTo = useCallback(
        index => {
            if (index < 0) {
                index = 0
            } else if (index >= length) {
                index = length - 1
            }
            setIndex(index)
            setLastIndex(index)
        },
        [length]
    )

    useEffect(() => {
        if (!active && typeof active !== "number") return
        if (active !== lastIndex) {
            goTo(active)
        }
    }, [active, lastIndex, goTo])

    const getDragX = (event, isTouch) => {
        return isTouch ? event.touches[0].pageX : event.pageX
    }

    const handleDragStart = (event, isTouch) => {
        if (length <= 1) return

        const x = getDragX(event, isTouch)

        dragStart.current = x
        dragStartTime.current = new Date()
        slideWidth.current = sliderRef.current.offsetWidth
        setTransition(false)
        if (!isTouch) mouseDown.current = true
    }

    const handleDragMove = (event, isTouch) => {
        if (length <= 1) return
        if (!isTouch && !mouseDown.current) return

        event.preventDefault()

        const x = getDragX(event, isTouch)
        if (x !== 0) {
            const offset = dragStart.current - x
            const percentageOffset = offset / slideWidth.current
            const newIndex = lastIndex + percentageOffset

            const SCROLL_OFFSET_TO_STOP_SCROLL = 30

            // Stop scrolling if you slide more than 30 pixels
            if (Math.abs(offset) > SCROLL_OFFSET_TO_STOP_SCROLL) {
                event.stopPropagation()
            }

            setIndex(newIndex)
        }
    }

    const handleDragEnd = isTouch => {
        if (length <= 1) return
        if (!isTouch && !mouseDown.current) return

        const timeElapsed = new Date().getTime() - dragStartTime.current.getTime()
        const offset = lastIndex - index
        const velocity = Math.round((offset / timeElapsed) * 10000)

        let newIndex = Math.round(index)

        if (Math.abs(velocity) > 5) {
            newIndex = velocity < 0 ? lastIndex + 1 : lastIndex - 1
        }

        if (newIndex < 0) {
            newIndex = 0
        } else if (newIndex >= length) {
            newIndex = length - 1
        }

        dragStart.current = 0
        setIndex(newIndex)
        setLastIndex(newIndex)
        setActive && setActive(newIndex)
        setTransition(true)
        mouseDown.current = false
    }

    useEffect(() => {
        const touchStart = event => {
            clientXStart.current = event.touches[0].clientX
        }

        const preventTouch = event => {
            const minValue = 30 // threshold

            clientX.current = event.touches[0].clientX - clientXStart.current

            // Vertical scrolling does not work when you start swiping horizontally.
            if (Math.abs(clientX.current) > minValue) {
                event.preventDefault()
                event.returnValue = false

                return false
            }
        }

        const slider = sliderRef.current
        if (slider) {
            slider.addEventListener("touchstart", touchStart)
            slider.addEventListener("touchmove", preventTouch, {
                passive: false,
            })
        }
        return () => {
            if (slider) {
                slider.removeEventListener("touchstart", touchStart)
                slider.removeEventListener("touchmove", preventTouch, {
                    passive: false,
                })
            }
        }
        // eslint-disable-next-line
    }, [])

    return {
        index,
        lastIndex,
        transition,
        goTo,
        handleDragEnd,
        handleDragMove,
        handleDragStart,
    }
}

export { useSlider }
