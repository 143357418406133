import { MuteButton, PlayButton } from "./video-button"
import React, { useEffect, useRef, useState } from "react"

import SelfHostedVideoStyles from "./self-hosted-video.module.css"
import { useDimensions } from "../../shareact/hooks/use-dimensions"
import { useIntersectionObserver } from "../../shareact/hooks/use-intersection-observer"

const SelfHostedVideo = props => {
    const [plays, setPlays] = useState(props.autoPlay)
    const [muted, setMuted] = useState(props.muted)
    const [progress, setProgress] = useState(0)

    const videoRef = useRef(null)

    const { width } = useDimensions()

    const intersected = useIntersectionObserver(videoRef)

    const lastWidth = useRef(width)

    useEffect(() => {
        if (!videoRef.current) return

        if ((lastWidth.current <= 767 && width > 767) || (lastWidth.current > 767 && width <= 767)) {
            videoRef.current.load()
            setPlays(true)
        }
        lastWidth.current = width
    }, [width])

    useEffect(() => {
        if (!intersected || !videoRef.current) return

        videoRef.current.load()
    }, [intersected])

    const sortVideos = videos => {
        const videoMapping = {
            "video/webm": 0,
            "video/mp4": 1,
        }

        return videos.sort((a, b) => videoMapping[a.type.find(t => t)] - videoMapping[b.type.find(t => t)])
    }

    const togglePlay = () => {
        if (!videoRef.current) return

        if (plays) {
            videoRef.current.pause()
        } else {
            videoRef.current.play()
        }

        setPlays(plays => !plays)
    }

    const handleOnTimeUpdate = () => {
        if (!videoRef.current) return

        const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100
        setProgress(progress || 0)
    }

    if (!props.videos) return null

    return (
        <div className={SelfHostedVideoStyles.wrapper}>
            <div className={SelfHostedVideoStyles.background} />
            <video
                loop
                autoPlay={props.autoPlay}
                muted={muted}
                preload="auto"
                playsInline
                ref={videoRef}
                onTimeUpdate={handleOnTimeUpdate}
                className={SelfHostedVideoStyles.video}
            >
                {sortVideos(props.videos).map(video => (
                    <source
                        key={video.id}
                        src={
                            intersected &&
                            `${process.env.IMG_CDN_URL_ASSETS}${
                                width <= 767 && video.src_mobile ? video.src_mobile : video.src
                            }`
                        }
                        type={video.type.find(t => t)}
                    />
                ))}
            </video>
            <button type="button" onClick={() => setMuted(muted => !muted)} className={SelfHostedVideoStyles.muteButton}>
                <MuteButton muted={muted} />
            </button>
            <button type="button" onClick={togglePlay} className={SelfHostedVideoStyles.playButton}>
                <PlayButton progress={progress} plays={plays} />
            </button>
        </div>
    )
}

SelfHostedVideo.defaultProps = {
    autoPlay: true,
    muted: true,
}

export default SelfHostedVideo
