import IconWithCircleStyles from "./icon-with-circle.module.css"
import React from "react"

const IconWithCircle = props => (
    <div
        className={IconWithCircleStyles.icon}
        style={{ background: props.background }}
    >
        <i className="lavita-icon" style={{ color: props.color }}>
            {props.icon}
        </i>
    </div>
)

export default IconWithCircle
