import BigIconStyles from "./big-icon.module.css"
import React from "react"
import classNames from "classnames"

const BigIcon = (props) => (
    <i className={classNames("lavita-icon", BigIconStyles.icon)}>
        {props.icon}
    </i>
)

export default BigIcon
